import { Container } from '@chakra-ui/react';
import { Hero, Layout } from 'bundle';
import { language } from 'lib';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';

const GetStarted = dynamic(() => import('bundle/components/landing/GetStarted'))
const WhyBarter = dynamic(() => import('bundle/components/landing/WhyBarter'))
const Academy = dynamic(() => import('bundle/components/landing/Academy'))
const Features = dynamic(() => import('bundle/components/landing/Features'))
const Supported = dynamic(() => import('bundle/components/landing/Crypto'))
const Bread = dynamic(() => import('bundle/components/landing/Bread'))

const BitBarterHome: NextPage = () => {
  return (
    <Layout activeNav="HOME" title={language.homeDefaultTitle}>
      <Container maxW="100%" p={0}>
        <Hero />
        <Features />
        <Supported />
        <GetStarted />
        <Academy />
        <WhyBarter />
        <Bread />
      </Container>
    </Layout>
  )
}
export default BitBarterHome
